// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher {
  font-weight: 600;
  color: white;
  background-color: #113418;
  border: none;
  border-radius: 10%;
  padding: 10px 10px 6px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/languageSwitcher/style.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,YAAA;EACA,yBCJW;EDKX,YAAA;EACA,kBAAA;EACA,2BAAA;AADF","sourcesContent":["@import \"../../styles/variables\";\n\n.language-switcher {\n  font-weight: 600;\n  color: white;\n  background-color: $main-color;\n  border: none;\n  border-radius: 10%;\n  padding: 10px 10px 6px 10px;\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
