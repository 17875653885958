import logoEN from "../../assets/logos/accuPartners/renovation_ministry_en.png";
import logoUa from "../../assets/logos/accuPartners/renovation_ministry_ua.png";

import { useTranslation } from "react-i18next";

export default function RestMin({ className = "partner-logo min-rest" }) {
  const { i18n, t } = useTranslation("translation", {
    keyPrefix: "partners.alts",
  });

  return (
    <img
      src={i18n.language === "en" ? logoEN : logoUa}
      alt={t("minRest")}
      className={className}
    />
  );
}
