import Button from "../Button/Button";
import { Images } from "../";
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="welcome">
      <LanguageSwitcher />
      <div className="container">
        <div className="hero-card">
          <div className="background">
            <div className="wrapper">
              <div className="text">
                <h5>{t("date")}</h5>
                <div className="wrap">
                  <Images.Location />
                  <span>{t("eventDetails.where")}</span>
                </div>
                <h1>{t("headText")}</h1>

                <Button
                  title={t("headerRegister")}
                  variant={"light"}
                  className={"register-btn"}
                  onClick={() => {
                    navigate("/registration");
                  }}
                />

                <Button
                  title={t("agendaMainTitle")}
                  className={"nav-btn"}
                  onClick={() => {
                    navigate("/agenda");
                  }}
                />
              </div>
            </div>
            {/* <div className="wrapper"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
