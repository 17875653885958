import React from "react";
import { Button, Images } from "../../../components";
import { useTranslation } from "react-i18next";
import "./style.scss";

const FormNavigation = ({
  onNext,
  onPrev,
  showNext = true,
  showPrev = true,
  nextDisabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`form-navigation ${
        showPrev == false && "justify-content-end"
      }`}
    >
      {showPrev && (
        <Button
          title={t("registerBackButton")}
          onClick={onPrev}
          variant={"dark"}
          beforeIcon={<Images.BackArrow color="#ffffff" />}
        />
      )}
      {showNext && (
        <Button
          title={t("registerNextButton")}
          onClick={onNext}
          variant={"light"}
          afterIcon={<Images.NextArrow />}
          disabled={nextDisabled}
        />
      )}
    </div>
  );
};

export default FormNavigation;
