import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Images } from "../../components";

const Partners = () => {
  const { t } = useTranslation("translation", { keyPrefix: "partners" });
  return (
    <div className="partners" id="partners">
      <div className="container">
        <h4>{t("hosted")}</h4>
        <div className="wrapper">
          <Images.Accu color="blue" />
        </div>

        <h4>{t("cooperation")}</h4>
        <div className="wrapper">
          <Images.CabMin />
          <Images.MinEnergy />
          <Images.RestMin />
        </div>
        <h4>{t("supported")}</h4>
        <div className="wrapper">
          <Images.FedMin />
          <Images.GIZ />
        </div>
      </div>
    </div>
  );
};

export default Partners;
