import React from "react";
import "./style.scss";

export default function Accordion({ id, title, time, content, empty }) {
  return (
    <div className="accordion" id={`agendaAccordion${id}`}>
      <div className="accordion-item">
        <h2 className="accordion-header" id={`heading${id}`}>
          <button
            className={`accordion-button ${empty && `empty`}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${id}`}
            aria-expanded="true"
            aria-controls={`collapse${id}`}
          >
            <div className="event">
              <p className="time">{time}</p>
              <p className="topic">{title}</p>
            </div>
          </button>
        </h2>
        {!empty && (
          <div
            id={`collapse${id}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading${id}`}
          >
            <div className="accordion-body">{content}</div>
          </div>
        )}
      </div>
    </div>
  );
}
