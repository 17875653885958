// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/bars-solid.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/icons/xmark-solid.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #000;
}
.header .navbar .navbar-brand img {
  width: 150px;
}
.header .navbar .navbar-toggler {
  padding: 0;
  border: none;
}
.header .navbar .navbar-toggler:hover, .header .navbar .navbar-toggler:active {
  box-shadow: none;
}
.header .navbar .navbar-toggler .navbar-toggler-icon {
  background-image: none;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
}
.header .navbar .navbar-toggler .navbar-toggler-icon.open {
  background-image: none;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.5rem;
  height: 2.5rem;
}
.header .navbar .navbar-nav .nav-item a {
  color: white;
  font-size: 1rem;
  font-weight: 500;
}
.header .navbar .navbar-nav .nav-item a:hover, .header .navbar .navbar-nav .nav-item a:active {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/header/style.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;AADF;AAIM;EACE,YAAA;AAFR;AAKI;EACE,UAAA;EACA,YAAA;AAHN;AAIM;EAEE,gBAAA;AAHR;AAKM;EACE,sBAAA;EACA,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,YAAA;AAHR;AAKM;EACE,sBAAA;EACA,yDAAA;EACA,4BAAA;EACA,wBAAA;EACA,aAAA;EACA,cAAA;AAHR;AASQ;EACE,YAAA;EACA,eAAA;EACA,gBAAA;AAPV;AAQU;EAEE,gBAAA;AAPZ","sourcesContent":["@import \"../../styles/variables\";\n\n.header {\n  background-color: #000;\n  .navbar {\n    .navbar-brand {\n      img {\n        width: 150px;\n      }\n    }\n    .navbar-toggler {\n      padding: 0;\n      border: none;\n      &:hover,\n      &:active {\n        box-shadow: none;\n      }\n      .navbar-toggler-icon {\n        background-image: none;\n        background-image: url(\"../../assets/icons/bars-solid.svg\");\n        background-repeat: no-repeat;\n        background-size: contain;\n        width: 2rem; // Set the width as needed\n        height: 2rem; // Set the height as needed\n      }\n      .navbar-toggler-icon.open {\n        background-image: none;\n        background-image: url(\"../../assets/icons/xmark-solid.svg\");\n        background-repeat: no-repeat;\n        background-size: contain;\n        width: 2.5rem; // Set the width as needed\n        height: 2.5rem; // Set the height as needed\n      }\n    }\n\n    .navbar-nav {\n      .nav-item {\n        a {\n          color: white;\n          font-size: 1rem;\n          font-weight: 500;\n          &:hover,\n          &:active {\n            font-weight: 700;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
