import logo from "../../assets/logos/accuPartners/FMEACA.png";

export default function FedMin({ className = "partner-logo fed-min" }) {
  return (
    <img
      src={logo}
      alt="Logotype of Federal Ministry for Economic Affairs and Climate Action"
      className={className}
    />
  );
}
