import logoEN from "../../assets/logos/accuPartners/energy_ministry_en.png";
import logoUa from "../../assets/logos/accuPartners/energy_ministry_ua.png";

import { useTranslation } from "react-i18next";

export default function MinEnergy({ className = "min-energy" }) {
  const { i18n, t } = useTranslation("translation", {
    keyPrefix: "partners.alts",
  });

  return (
    <img
      src={i18n.language === "en" ? logoEN : logoUa}
      alt={t("minEnergy")}
      className={`partner-logo ${className}`}
    />
  );
}
