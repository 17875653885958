// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: black;
  color: white;
  padding: 2rem 0 1rem 0;
}
.footer .container .partner-logo {
  width: 15rem;
  margin: 0;
}

.bottom-content .copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
}
.bottom-content .developer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}
.bottom-content .developer a {
  text-decoration: none;
  color: white;
  font-weight: 700;
}

@media (min-width: 768px) {
  .footer .container .row .footer-logo .logo {
    width: 10rem;
  }
  .footer .bottom-content .copyright {
    padding: 2rem 0;
  }
  .footer .bottom-content .developer {
    align-items: flex-end;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/footer/style.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,YAAA;EACA,sBAAA;AADF;AAGI;EACE,YAAA;EACA,SAAA;AADN;;AAME;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AAHJ;AAKE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AAHJ;AAII;EACE,qBAAA;EACA,YAAA;EACA,gBAAA;AAFN;;AAWA;EAKU;IACE,YAAA;EAZV;EAkBI;IACE,eAAA;EAhBN;EAkBI;IACE,qBAAA;EAhBN;AACF","sourcesContent":["@import \"../../styles/variables\";\n\n.footer {\n  background-color: black;\n  color: white;\n  padding: 2rem 0 1rem 0;\n  .container {\n    .partner-logo {\n      width: 15rem;\n      margin: 0;\n    }\n  }\n}\n.bottom-content {\n  .copyright {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 2rem 1rem;\n  }\n  .developer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 2rem 0;\n    a {\n      text-decoration: none;\n      color: white;\n      font-weight: 700;\n    }\n  }\n}\n\n@media (min-width: 576px) {\n  // Styles for small devices\n}\n\n@media (min-width: 768px) {\n  .footer {\n    .container {\n      .row {\n        .footer-logo {\n          .logo {\n            width: 10rem;\n          }\n        }\n      }\n    }\n    .bottom-content {\n      .copyright {\n        padding: 2rem 0;\n      }\n      .developer {\n        align-items: flex-end;\n      }\n    }\n  }\n}\n\n@media (min-width: 992px) {\n  // Styles for large devices\n}\n\n@media (min-width: 1200px) {\n  // Styles for extra large devices\n}\n\n@media (min-width: 1400px) {\n  // Styles for extra extra large devices\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
