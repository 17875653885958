import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { Registration, Main, Agenda } from "./pages";
import "./App.scss";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/agenda" element={<Agenda />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
