import React from "react";
import { useTranslation } from "react-i18next";
import "./style.scss";
import { Images } from "..";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer" id="footer">
      <div className="container line-border-light">
        <Images.Accu />
      </div>
      <div className="container">
        <div className="row bottom-content">
          <div className="col-12 col-md-6 copyright">
            <span>{t("footerCopyright")}</span>
          </div>
          <div className="col-12 col-md-6 developer">
            <span>{t("footerDeveloper")}</span>
            <a href={"https://convalia.digital"}>convalia.digital</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
