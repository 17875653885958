import logo from "../../assets/logos/accuPartners/giz.png";

export default function GIZ({ className = "partner-logo" }) {
  return (
    <img
      src={logo}
      alt="Німецьке товариство міжнародного співробітництва"
      className={className}
    />
  );
}
