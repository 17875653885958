import React, { useState } from "react";
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import "./style.scss";

const Header = () => {
  // const { t, i18n } = useTranslation();
  // const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="header">
      <nav className="navbar navbar-expand-lg ">
        <div className="container">
          <LanguageSwitcher />
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className={`navbar-toggler-icon ${isOpen ? "open" : ""}`}
              onClick={() => setIsOpen(!isOpen)}
            ></span>
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <a className="nav-link" href="/">
                  {t("headerMain")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/registration">
                  {t("headerRegister")}
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
