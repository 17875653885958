// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  display: flex;
  font-weight: 500;
  background: #113418;
  border: none;
  border-radius: 0.5rem;
}

.light {
  background: #f5f6f6;
  color: black;
}

.dark {
  background: #1c1b1a;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/style.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,gBAAA;EACA,mBCJW;EDKX,YAAA;EACA,qBAAA;AADF;;AAIA;EACE,mBCRW;EDSX,YAAA;AADF;;AAIA;EACE,mBCdU;EDeV,YAAA;AADF","sourcesContent":["@import \"../../styles/variables\";\n\n.button {\n  display: flex;\n  font-weight: 500;\n  background: $main-color;\n  border: none;\n  border-radius: 0.5rem;\n}\n\n.light {\n  background: $light-gray;\n  color: black;\n}\n\n.dark {\n  background: $dark-gray;\n  color: white;\n}\n","//colors\n$main-color: #113418;\n$dark-gray: #1c1b1a;\n$light-gray: #f5f6f6;\n\n//gradients\n$gray-green-gradient: linear-gradient(90deg, $dark-gray, $main-color);\n$green-gray-gradient: linear-gradient(90deg, #133418, #1c1b1a);\n\n//font-weight:\n\n$bold: 700;\n$semibold: 500;\n$medium: 300;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
